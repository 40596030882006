import classNames from "classnames";
import Link from "next/link";
import { Fragment, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { track } from "static/lib/tracking";
import Text from "../../atoms/Text/Text";

import { AspectRatio, type CustomerMarketingCarouselItemAsset } from "@/generated/requests/services";
import { resizeImageIfNeeded } from "@/lib/helpers";
import "slick-carousel/slick/slick.css";
import IconPauseFilled from "../../atoms/Icons/PauseFilled";
import IconPlayFilled from "../../atoms/Icons/PlayFilled";
import { useTranslation } from "next-i18next";
import { handleKeyDown } from "@/static/lib/util";

type Orientation = "vertical" | "horizontal";

// A shorter interval yields a smoother animation, but we don't want to update
// too frequently either. Time in milliseconds.
const timerInterval = 250;

const verticalAspectRatios = [AspectRatio.X3_4, AspectRatio.X9_16, AspectRatio.X9_21];

const horizontalAspectRatios = [AspectRatio.X4_3, AspectRatio.X16_9, AspectRatio.X21_9];

const assetForOrientation = (
  assets: CustomerMarketingCarouselItemAsset[],
  orientation: Orientation,
  defaultAsset?: Omit<CustomerMarketingCarouselItemAsset, "aspectRatio"> & { aspectRatio?: AspectRatio },
) =>
  assets.reduce((asset, next) => {
    // always use the largest aspect ratio, use the next if no ratio or default
    const ratios = orientation === "vertical" ? verticalAspectRatios : horizontalAspectRatios;
    if (!asset || !asset.aspectRatio || ratios.indexOf(next.aspectRatio) > ratios.indexOf(asset.aspectRatio)) {
      return next;
    }

    // use the current asset
    return asset;
  }, defaultAsset);

function SlideItem({
  actionEventName,
  actionTitle,
  actionTitleBackgroundColor,
  actionTitleColor,
  actionUrl,
  assets,
  backgroundColor,
  description,
  descriptionColor,
  slug,
  title,
  titleColor,
  // deprecated fields, use assets
  assetType: oldAssetType,
  assetUrl: oldAssetUrl,
  // pass-through props
  orientation,
  heightClassName,
}) {
  const videoRef = useRef<HTMLVideoElement>();
  const [isPlaying, setIsPlaying] = useState(true);
  const { assetUrl, assetType } = assetForOrientation(
    assets,
    orientation,
    assets[0] || { assetType: oldAssetType, assetUrl: oldAssetUrl },
  );

  const handlePlayPause = () => {
    isPlaying ? videoRef.current.pause() : videoRef.current.play();
    setIsPlaying(!isPlaying);

    const announcement = isPlaying ? t("common:video_paused_now") : t("common:video_playing_now");
    const liveRegion = document.getElementById("live-region");
    if (liveRegion) {
      liveRegion.textContent = announcement;
    }
  };
  const PlayPauseIcon = isPlaying ? IconPauseFilled : IconPlayFilled;
  const { t } = useTranslation();

  return (
    <div key={slug} style={{ backgroundColor }} className="relative">
      <div className="absolute inset-0">
        {assetType === "VIDEO" ? (
          <Fragment>
            <button
              className="absolute right-2 bottom-2 p-2 opacity-70 rounded-full bg-white z-20"
              onClick={handlePlayPause}
              aria-label={isPlaying ? t("common:pause_video") : t("common:play_video")}
              onKeyDown={(e) => handleKeyDown(e, handlePlayPause)}
            >
              <PlayPauseIcon />
            </button>
            <video
              key={assetUrl}
              ref={videoRef}
              autoPlay
              muted
              playsInline
              loop
              className="w-full h-full object-cover object-center"
            >
              <source src={assetUrl} type="video/mp4" />
            </video>
            <div id="live-region" className="sr-only" aria-live="polite"></div>
          </Fragment>
        ) : (
          <img
            src={resizeImageIfNeeded(assetUrl, 1920)}
            alt={title}
            className="w-full h-full object-cover object-center"
          />
        )}
      </div>

      <div className={classNames("relative max-w-9xl mx-auto", heightClassName)}>
        <div
          className={classNames(
            "absolute flex flex-col justify-center bottom-8 left-0 right-0 px-[15px] md:px-[40px] py-4 text-sm md:text-base lg:top-8 lg:text-center lg:items-center",
            {
              // if its only a button, bottom center position
              "!justify-end": !title && !description && !!actionUrl,
            },
          )}
        >
          <div className="flex flex-col">
            {title && (
              <Text
                variant="display1"
                style={{ color: titleColor }}
                className={classNames(
                  "!text-[44px] !leading-[50px] md:!text-[60px] md:!leading-[66px] lg:!text-[100px] lg:!leading-[110px]",
                  "pb-[6px] lg:pb-[12px]",
                )}
              >
                {title}
              </Text>
            )}
            {description && (
              <Text
                variant="body1"
                className={classNames(
                  "!text-[14px] leading-[18px] md:!text-[24px] md:!leading-[30px] lg:!text-[28px] lg:!leading-[32px]",
                  {
                    "!pb-[20px] lg:!pb-[40px]": !!actionUrl,
                  },
                )}
                style={{ color: descriptionColor }}
              >
                {description}
              </Text>
            )}
            {actionUrl && (
              <div className={classNames("flex justify-left lg:justify-center")}>
                <Link
                  className={classNames(
                    "flex justify-center items-center h-10 md:h-[50px] font-bold  capitalize rounded-full w-auto px-8",
                    { "bg-black": !actionTitleBackgroundColor, "text-white": !actionTitleColor },
                  )}
                  style={{ backgroundColor: actionTitleBackgroundColor, color: actionTitleColor }}
                  // disabling tabbing because it causes the carousel to show multiple items at a time
                  tabIndex={-1}
                  href={actionUrl}
                  onClick={() => {
                    track({
                      event: "trackEvent",
                      eventLabel: "Marketing action clicked",
                      eventCategory: "Marketing Carousel",
                      eventAction: "Clicked",
                      eventValue: actionEventName,
                    });
                  }}
                >
                  {actionTitle}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function MarketingCarousel({ items }) {
  const sliderRef = useRef<Slider>();
  const timerIntervalRef = useRef(null);
  const pauseRef = useRef(false);
  const [milliseconds, setMilliseconds] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [orientation, setOrientation] = useState<Orientation>("vertical");
  const [itemsForOrientation, setItemsForOrientation] = useState([]);

  // server sends duration as seconds
  const duration = (items?.[slideIndex]?.duration || 15) * 1000;

  const startTimer = () => {
    // Prevent accidentally starting duplicate timers/intervals
    if (timerIntervalRef.current) {
      return;
    }

    timerIntervalRef.current = setInterval(() => {
      // pause counting when the user touches/clicks the carousel
      if (pauseRef.current) {
        return;
      }

      setMilliseconds((prev) => {
        const updated = prev + timerInterval;

        if (updated > duration) {
          sliderRef.current?.slickNext?.();
        }

        return updated;
      });
    }, timerInterval);
  };

  const stopTimer = () => {
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }
  };

  useEffect(() => {
    startTimer();
  }, [duration]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const eventListener = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        if (width >= height) {
          setItemsForOrientation(
            items.filter((item) => item.assets?.some((asset) => horizontalAspectRatios.includes(asset.aspectRatio))),
          );
          if (orientation === "vertical") {
            setOrientation("horizontal");
          }
        }

        if (width < height) {
          setItemsForOrientation(
            items.filter((item) => item.assets?.some((asset) => verticalAspectRatios.includes(asset.aspectRatio))),
          );
          if (orientation === "horizontal") {
            setOrientation("vertical");
          }
        }
      };

      // invoke once to make sure we get the correct orientation on initial load
      eventListener();

      window.addEventListener("resize", eventListener);
      return () => {
        window.removeEventListener("resize", eventListener);
      };
    }
  }, [orientation, items]);

  //desktop: 1920x864
  //tablet: 960x864
  //mobile: 100vhx100vw
  const heightClassName = "h-[80svh]";
  return (
    <div
      className={classNames("bg-primary overflow-hidden", heightClassName)}
      onTouchStart={() => (pauseRef.current = true)}
      onTouchEnd={() => (pauseRef.current = false)}
      onTouchCancel={() => (pauseRef.current = false)}
    >
      <Slider
        ref={sliderRef}
        dots
        dotsClass="!flex absolute bottom-8 left-[15px] md:left-[40px] gap-1 lg:left-0 lg:right-0 lg:justify-center"
        infinite
        arrows={false}
        beforeChange={(_, index) => {
          stopTimer();
          setMilliseconds(0);
          setSlideIndex(index);
        }}
        afterChange={() => {
          startTimer();
        }}
        customPaging={(index) => {
          const isPreviousSlide = index < slideIndex;
          const isCurrentSlide = index === slideIndex;
          const progress = (1 - (1 - (duration - milliseconds) / duration)) * 100 || 0;
          return (
            <div
              className={classNames(
                "transition-all h-1.5 w-1.5 rounded-full overflow-clip",
                isPreviousSlide ? "bg-black" : "bg-black/30",
                isCurrentSlide && "w-6",
              )}
            >
              {isCurrentSlide && (
                <div
                  className="w-full h-full bg-black ease-linear transition-transform"
                  style={{
                    transform: `translateX(-${progress}%)`,
                    transitionDuration: `${timerInterval}ms`,
                  }}
                />
              )}
            </div>
          );
        }}
      >
        {itemsForOrientation.map((item) => (
          <SlideItem key={item.slug} orientation={orientation} heightClassName={heightClassName} {...item} />
        ))}
      </Slider>
    </div>
  );
}
